import React from "react";
import ArrowBottom from "../images/arrow-bottom.png";
import { motion } from "framer-motion";
import { bounceInDown } from "./motion-variants";

const ArrowButton = ({ link }) => {
  return typeof window !== `undefined` && window.innerWidth >= 992 ? (
    <motion.a
      href={`#${link}`}
      className="scroll-bottom animate__animated animate__pulse animate__infinite"
      initial="hidden"
      animate="visible"
      variants={bounceInDown}
    >
      <img src={ArrowBottom} alt="wki scroll down button" />
    </motion.a>
  ) : (
    <span></span>
  );
};

export default ArrowButton;
