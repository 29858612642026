export const slideInDown = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5,
    },
  },
};

export const fadeInLeft = {
  hidden: { x: "-100vw", opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  anim1: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  anim2: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 1,
    },
  },
  anim3: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 1.8,
    },
  },
  anim4: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 2.6,
    },
  },
};

export const fadeInRight = {
  hidden: { x: "100vw", opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

export const bounceInDown = {
  hidden: { y: -20 },
  visible: {
    y: 0,
    transition: {
      repeat: Infinity,
      repeatType: "reverse",
      type: "spring",
      duration: 2,
      bounce: 0.6,
    },
  },
};
