import ReactFullpage from "@fullpage/react-fullpage";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import BaseLayout from "../components/base-layout";
import Footer from "../components/footer";
import VideoSection from "../components/video-bg-section";
import BenefitOne from "../images/benefit/benefit-1.jpg";
import BenefitTwo from "../images/benefit/benefit-2.jpg";
import BenefitThree from "../images/benefit/benefit-3.jpg";
import BenefitFour from "../images/benefit/benefit-4.jpg";
import ChatButton from "../images/chat.png";
import Bottle from "../images/items/bottle.png";
import MachineOne from "../images/items/machine1.png";
import MachineTwo from "../images/items/machine2.png";
import JumbotronBg from "../images/jumbotron-bg.png";
import HlineOne from "../images/lines/hline1.png";
import HlineTwo from "../images/lines/hline2.png";
import HlineThree from "../images/lines/hline3.png";
import VlineOne from "../images/lines/vline1.png";
import ArrowButton from "../reusable/arrow-button";
import {
    fadeInLeft,
    fadeInRight, slideInDown
} from "../reusable/motion-variants";
import "../styles/wki.css";

const MainPage = () => {
  const innovateAnimControl = useAnimation();
  const reasonOneControl = useAnimation();
  const reasonTwoControl = useAnimation();
  const reasonThreeControl = useAnimation();
  const reasonFourControl = useAnimation();
  const aboutAnimControl = useAnimation();

  const [navLogo, setNavLogo] = useState("white");
  const [fpPaddingTop, setFpPaddingTop] = useState(0);
  const [fpPaddingBottom, setFpPaddingBottom] = useState(0);
  const [useNavigation, setUseNavigation] = useState(true);

  useEffect(() => {
    if (typeof window !== `undefined` && window.innerWidth < 991) {
      setFpPaddingTop("86px");
      setFpPaddingBottom("5em");
      setUseNavigation(false);
    }
  }, []);

  return (
    <BaseLayout color={navLogo} withFooter={false}>
      <a href="https://wa.me/6281334954825">
        <img
          src={ChatButton}
          alt="chat with us"
          className="img-fluid floating-btn"
        />
      </a>

      <ReactFullpage
        // autoScrolling={true}
        scrollOverflow={true}
        anchors={["home", "innovation", "reasons", "about", "footer"]}
        paddingTop={fpPaddingTop}
        paddingBottom={fpPaddingBottom}
        scrollingSpeed={1200}
        navigation={useNavigation}
        responsiveHeight={640}
        verticalCentered={true}
        onLeave={(origin, destination, direction) => {
          if (typeof window !== `undefined` && window.innerWidth >= 992) {
            if (destination.isFirst === true) {
              setNavLogo("white");
            } else {
              setNavLogo("black");

              if (destination.anchor === "innovation") {
                innovateAnimControl.start("visible");
              } else if (destination.anchor === "reasons") {
                reasonOneControl.start("anim1");
                reasonTwoControl.start("anim2");
                reasonThreeControl.start("anim3");
                reasonFourControl.start("anim4");
              } else if (destination.anchor === "about") {
                aboutAnimControl.start("visible");
              }
            }
          }
        }}
        render={({ fullpageApi }) => {
          if (
            typeof window !== `undefined` &&
            window.innerWidth < 991 &&
            fullpageApi
          ) {
            fullpageApi.destroy("all");
            innovateAnimControl.start("visible");
            reasonOneControl.start("anim1");
            reasonTwoControl.start("anim2");
            reasonThreeControl.start("anim3");
            reasonFourControl.start("anim4");
            aboutAnimControl.start("visible");
          }

          return (
            <ReactFullpage.Wrapper>
              {/* <VideoSection
                customClasses="first-section mt-5"
                customId="first-bg"
                anchor="home"

              > */}
              <section
                className="first-section section"
                data-anchor="home"
                style={{
                  backgroundImage: `linear-gradient(90deg, #FAFAFA, transparent), url(${JumbotronBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-8 d-flex flex-column justify-content-center align-items-center pt-5">
                      <motion.img
                        className="img-fluid"
                        src={Bottle}
                        alt="wki services illustration"
                        initial="hidden"
                        animate="visible"
                        variants={slideInDown}
                      />
                      <img
                        className="img-fluid"
                        src={HlineTwo}
                        alt="gradient line"
                      />
                    </div>
                    <div className="col-sm-4 d-flex flex-column justify-content-center align-items-start position-relative pb-5">
                      <img
                        className="img-fluid"
                        src={VlineOne}
                        alt="gradient line"
                      />
                      <motion.span
                        className="tagline"
                        initial="hidden"
                        animate="visible"
                        variants={fadeInRight}
                      >
                        All-In-One
                        <br />
                        Innovative
                        <br />
                        Cosmetic Brand
                        <br />
                        Formulation & Solution
                      </motion.span>

                      <img
                        className="img-fluid"
                        src={HlineOne}
                        alt="gradient line"
                        style={{ maxWidth: "93%" }}
                      />
                    </div>
                  </div>
                </div>

                <ArrowButton link="innovation" />
              </section>

              <VideoSection
                customClasses={"second-section"}
                customId="second-bg"
                anchor="innovation"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-sm-6 d-flex flex-column justify-content-center align-items-center pt-5">
                      <motion.img
                        className="img-fluid"
                        src={MachineOne}
                        id="machine1"
                        alt="wki services illustration"
                        initial="hidden"
                        animate={innovateAnimControl}
                        variants={slideInDown}
                      />
                    </div>
                    <motion.div
                      className="col-sm-6 d-flex flex-column justify-content-center align-items-start position-relative"
                      initial="hidden"
                      animate={innovateAnimControl}
                      variants={fadeInRight}
                    >
                      <img
                        className="img-fluid"
                        src={VlineOne}
                        alt="gradient line"
                      />
                      <span className="section-title mb-5">
                        One Stop Solution for Your Cosmetic’s Brand
                      </span>
                      <span className="section-desc">
                        Jasa maklon kosmetik untuk menciptakan produk kosmetik
                        berkualitas impian anda dengan harga bersaing. Kami
                        bantu mulai dari perancangan konsep produk, desain
                        hingga pemasaran. Efisien dan anti ribet. Buktikan
                        sekarang!
                      </span>
                      <img
                        className="img-fluid"
                        src={HlineOne}
                        alt="gradient line"
                      />
                    </motion.div>
                  </div>
                </div>

                <ArrowButton link="reasons" />
              </VideoSection>

              <VideoSection
                customClasses="third-section"
                customId="third-bg"
                anchor="reasons"
              >
                <div className="container">
                  <div className="section-title mb-5 position-relative">
                    Mengapa Kami ?
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <motion.div
                        className="card"
                        initial="hidden"
                        animate={reasonOneControl}
                        variants={fadeInLeft}
                      >
                        <img
                          src={BenefitOne}
                          className="card-img-top"
                          alt="benefit to choose wki illustration"
                        />
                        <div className="card-body">
                          <h5 className="card-title benefit-title">
                            One Stop Service
                          </h5>
                          <p className="card-text section-desc">
                            Cukup sampaikan keinginan Anda pada kami, kami bantu
                            wujudkan mulai dari konsep hingga pemasaran.
                          </p>
                          <img
                            className="img-fluid"
                            src={HlineThree}
                            alt="gradient line"
                          />
                        </div>
                      </motion.div>
                    </div>
                    <div className="col-md">
                      <motion.div
                        className="card"
                        initial="hidden"
                        animate={reasonTwoControl}
                        variants={fadeInLeft}
                      >
                        <img
                          src={BenefitTwo}
                          className="card-img-top"
                          alt="benefit to choose wki illustration"
                        />
                        <div className="card-body">
                          <h5 className="card-title benefit-title">
                            Certified Manufacturing
                          </h5>
                          <p className="card-text section-desc">
                            Perusahaan kami sudah tersertifikasi mulai dari
                            alat, bahan hingga tim profesional, sehingga produk
                            yang dihasilkan berkualitass dan aman bagi konsumen.
                          </p>
                          <img
                            className="img-fluid"
                            src={HlineThree}
                            alt="gradient line"
                          />
                        </div>
                      </motion.div>
                    </div>
                    <div className="col-md">
                      <motion.div
                        className="card"
                        initial="hidden"
                        animate={reasonThreeControl}
                        variants={fadeInLeft}
                      >
                        <img
                          src={BenefitThree}
                          className="card-img-top"
                          alt="benefit to choose wki illustration"
                        />
                        <div className="card-body">
                          <h5 className="card-title benefit-title">
                            Based On Budget
                          </h5>
                          <p className="card-text section-desc">
                            Kami bisa menyesuaikan modal yang Anda miliki dengan
                            produk yang dapat kami wujudkan spesial untuk Anda.
                          </p>
                          <img
                            className="img-fluid"
                            src={HlineThree}
                            alt="gradient line"
                          />
                        </div>
                      </motion.div>
                    </div>
                    <div className="col-md">
                      <motion.div
                        className="card"
                        initial="hidden"
                        animate={reasonFourControl}
                        variants={fadeInLeft}
                      >
                        <img
                          src={BenefitFour}
                          className="card-img-top"
                          alt="benefit to choose wki illustration"
                        />
                        <div className="card-body">
                          <h5 className="card-title benefit-title">
                            We Understand You
                          </h5>
                          <p className="card-text section-desc">
                            Kami menyediakan waktu spesial bagi Anda untuk
                            konsultasi dengan kami agar bisa memahami ide yang
                            ingin Anda wujudkan jadi nyata!
                          </p>
                          <img
                            className="img-fluid"
                            src={HlineThree}
                            alt="gradient line"
                          />
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>

                <ArrowButton link="about" />
              </VideoSection>

              <VideoSection
                customClasses="fourth-section mb-5"
                customId="fourth-bg"
                anchor="about"
              >
                <div className="container mb-5">
                  <div className="row justify-content-center">
                    <motion.div
                      className="col-sm-6 d-flex flex-column justify-content-center align-items-start position-relative"
                      initial="hidden"
                      animate={aboutAnimControl}
                      variants={fadeInLeft}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span className="section-title mb-5">About Us</span>
                        <img
                          className="img-fluid"
                          src={VlineOne}
                          alt="gradient line"
                          style={{ maxHeight: 50 }}
                        />
                      </div>
                      <span className="section-desc">
                        PT. Wahana Kosmetika Indonesia (PT. WKI) terletak di
                        kota sidoarjo dengan lokasi strategis yang dekat dari
                        manapun memudahkan Anda untuk mendistribusikan produk.
                        PT. WKI memiliki tim profesional ditunjang dengan
                        alat-alat canggih dan bahan berkualitas untuk dapat
                        mengembangkan konsep yang ingin Anda wujudkan menjadi
                        nyata. Salah satu brand kosmetik yang telah menggunakan
                        jasa kami ialah Azarine Cosmetic.
                      </span>
                      <img
                        className="img-fluid"
                        src={HlineOne}
                        alt="gradient line"
                      />
                    </motion.div>
                    <div className="col-sm-6 d-flex flex-column justify-content-center align-items-center pt-5">
                      <motion.img
                        className="img-fluid"
                        src={MachineTwo}
                        alt="wki services illustration"
                        initial="hidden"
                        animate={aboutAnimControl}
                        variants={slideInDown}
                      />
                    </div>
                  </div>
                </div>

                <ArrowButton link="footer" />
              </VideoSection>

              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    <React.Fragment></React.Fragment></BaseLayout>
  );
};

export default MainPage;
