import React from "react";
import styled from "styled-components";
import webmBg from "../images/bg/wave.webm";
import mp4Bg from "../images/bg/wave.mp4";

const VideoBg = styled.video`
  position: absolute;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  height: auto;
  top: 0;
`;

const VideoSection = ({ customClasses, customId, anchor, children, style }) => {
  return (
    <section
      className={`${customClasses} section`}
      data-anchor={anchor}
      style={style}
    >
      {typeof window !== `undefined` && window.innerWidth > 920 ? (
        <VideoBg
          className="video-bg"
          id={customId}
          autoPlay
          loop
          muted
          data-keepplaying
        >
          <source src={webmBg} type="video/webm" />
          {/* <source src={mp4Bg} type="video/mp4" /> */}
          Your browser does not support the video tag.
        </VideoBg>
      ) : (
        ""
      )}

      {children}
    </section>
  );
};

export default VideoSection;
